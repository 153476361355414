// const API = "https://playwisebackend.azurewebsites.net/admin/v1";
// // HEAD
// // const TEST_API = "https://playwisebackendtest.azurewebsites.net/admin/v1";
// // const TEST_API= "https://playwisebackendtest.azurewebsites.net/admin/v1/"
// // =======
// const TEST_API = "https://playwisebackendtest.azurewebsites.net/admin/v1";
// // const TEST_API= "https://pw-backend-dun.vercel.app/admin/v1"
// // >>>>>>> a340d4577cf2ce97f5339f26ca1aa0db1811989d
// const DEV_API = "http://localhost:5000/admin/v1";

// export const env = process.env.REACT_APP_ENV;

// export const getEnv = () => {
//   return env;
// };

// export function getApi() {
//   switch (env) {
//     case "test":
//       return TEST_API;
//       break;
//     case "development":
//       return DEV_API;
//       break;
//     default:
//       return API;
//   }
// }
// const API = "https://playwisebackend.azurewebsites.net/admin/v1";
const API = "https://api.playwise.gg/api/admin/v1";
// HEAD
// const TEST_API = "https://playwisebackendtest.azurewebsites.net/admin/v1";
// const TEST_API= "https://playwisebackendtest.azurewebsites.net/admin/v1/"
// =======
// const TEST_API = "https://playwisebackendtest.azurewebsites.net/admin/v1";
const TEST_API = "https://stg.playwise.gg/admin/v1";
// const TEST_API= "https://pw-backend-dun.vercel.app/admin/v1"
// >>>>>>> a340d4577cf2ce97f5339f26ca1aa0db1811989d
const DEV_API = "http://localhost:5000/admin/v1";

export const env = process.env.REACT_APP_ENV;

export const getEnv = () => {
  return env;
};

export function getApi() {
  switch (env) {
    case "test":
      return TEST_API;
      break;
    case "development":
      return DEV_API;
      break;
    default:
      return API;
  }
}
